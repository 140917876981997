<template>
  <ion-page class="page scrollable no-padding">
    <NonAuthenticatedHome v-if="!isAuthenticated" />
    <div class="bg" />
  </ion-page>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import NonAuthenticatedHome from './components/NonAuthenticatedHome/index.vue';

const { isAuthenticated } = authStore();
</script>
<style lang="sass" scoped>
.page
  padding: 0 !important
  min-width: 100vw !important
  .bg
    background: url('/bg.svg')
    background-repeat: repeat
    position: absolute
    width: 300%
    height: 100%
    opacity: 0.09
    left: -100%
    top: 0
</style>
